import React from 'react';
import { Box, Flex } from '@rebass/emotion';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from '@emotion/styled';

import Wrap from '../components/Wrap';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Button from '../components/Button';
import ImageLoad from '../components/ImageLoad';

const TextCaps = styled(Text)`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2em;
  position: relative;
`;

const Coffee = () => (
  <Layout nocoffee>
    <SEO title="Citra. Product consultation for new founders." keywords={[`contact`, `ux`, `product`, `design`, `studio`]} />
    <Box>
      <Wrap flexWrap="wrap" py={['8vh', '10vh']}>
        <Box data-aos="fade-up" ml={[0, 0, '10%']} mb={[1, 5]} width={[1, 5/10, 5/10]}>
          <Heading as="h2" fontSize={[5,6,8]} mb={2} color="white">Let's Get a Coffee</Heading>
          <Text color="white" fontSize={[ 1, 3, 4 ]} mb={[4, 6, 6]}>We enjoy meeting new founders and talking about product and strategy. We offer free consultations, no strings attached.</Text>
          <Button light href="mailto:hello@citra.co">Schedule a Coffee</Button>
        </Box>
        <Box py={['5vh', '10vh']} width={1} data-aos="fade-up" data-aos-delay="100">
          <Flex flexWrap="wrap">
            <Box width={1}>
              <TextCaps color="white">How We Can Help</TextCaps>
            </Box>
            <Box width={[1, 9/10]}>
              <Flex flexWrap="wrap">
                <Box width={[1, 5/10, 4/12]} pr={[0, 6, 6]} mb={6}>
                  <Heading color="white" as="h3" fontSize={[3, 4, 4]} mb={2}>Product Strategy</Heading>
                  <Text color="white" fontSize={[0, 1, 1]}>What are the achievable goals behind your product? What is your larger vision and what steps do you need to take to get there?</Text>
                </Box>
                <Box width={[1, 5/10, 4/12]} pr={[0, 6, 6]} mb={6}>
                  <Heading color="white" as="h3" fontSize={[3, 4, 4]} mb={2}>UX & Design</Heading>
                  <Text color="white" fontSize={[0, 1, 1]}>How can you improve UX in your ecosystem both on screen and off? How do you identify user pain points? What's your plan to address them?</Text>
                </Box>
                <Box width={[1, 5/10, 4/12]} pr={[0, 6, 6]} mb={6}>
                  <Heading color="white" as="h3" fontSize={[3, 4, 4]} mb={2}>Technology</Heading>
                  <Text color="white" fontSize={[0, 1, 1]}>What platform should you build on? How will your product scale? How do you hire the right engineering team depending on your strategy?</Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box pb={['4vh', '10vh']} width={1} data-aos="fade-up" data-aos-delay="100">
          <Flex flexWrap="wrap">
            <Box width={1}>
              <TextCaps color="white">Who You'll Meet</TextCaps>
            </Box>
            <Box width={[1, 9/10]}>
              <Flex flexWrap="wrap">
                <Box width={[1, 5/10]} pr={[0, 6, 6]} mb={6}>
                  <Flex flexWrap="wrap" alignItems="center">
                    <Box width={1/4}>
                      <ImageLoad imgsrc="scott.jpg" imgalt="Scott Simpson, Cofounder" />
                    </Box>
                    <Box width={3/4} pl={4}>
                      <Heading color="white" as="h3" fontSize={[3, 4, 4]} mb={1}>Scott Simpson</Heading>
                      <Text color="white" fontSize={[0, 1, 1]}>Cofounder</Text>
                    </Box>
                  </Flex>
                </Box>
                <Box width={[1, 5/10]} pr={[0, 6, 6]} mb={6}>
                  <Flex flexWrap="wrap" alignItems="center">
                    <Box width={1/4}>
                      <ImageLoad imgsrc="chetan.jpg" imgalt="Chetan Shenoy, Cofounder" />
                    </Box>
                    <Box width={3/4} pl={4}>
                      <Heading color="white" as="h3" fontSize={[3, 4, 4]} mb={1}>Chetan Shenoy</Heading>
                      <Text color="white" fontSize={[0, 1, 1]}>Cofounder</Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Wrap>
    </Box>
  </Layout>
)

export default Coffee;
